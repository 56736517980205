<template>
  <b-overlay :show="showOverlay">
    <b-row>
      <b-col>
        <p>Dernière mise à jour du classement : {{ updateDate }}</p>
        <p>
          Ce classement prend en compte les 4 meilleures courses entre le
          01/09/2020 et le 15/12/2021 avec au moins une course nationale.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Embarcation :" label-for="boatType">
          <b-form-select
            id="boatType"
            v-model="form.boatType"
            :options="boatTypes"
            @change="updateFilteredAndSearchedBoats"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Catégorie :" label-for="category">
          <b-form-select
            id="category"
            v-model="form.category"
            :options="categories"
            @change="updateFilteredAndSearchedBoats"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Division :" label-for="level">
          <b-form-select
            id="level"
            v-model="form.level"
            :options="levels"
            @change="updateFilteredAndSearchedBoats"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Nombre de bateaux à afficher :"
          label-for="boatNumber"
        >
          <b-form-select
            id="boatNumber"
            v-model="form.boatNumber"
            :options="[20, 50, 100]"
            @change="updateFilteredAndSearchedBoats"
            required
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Nom :" label-for="name">
          <b-form-input id="name"
            v-model="form.name" @input="updateSearchedBoats" required
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          striped
          borderless
          small
          :items="filteredAndSearchedBoats"
          :fields="fieldsToutes"
          :per-page="form.boatNumber"
          :current-page="currentPage"
        >
          <template v-slot:cell(club)="data">
            {{
              data.item.clubs
                ? data.item.clubs
                    .map((e) => e.clubName)
                    .join("/")
                    .toUpperCase()
                : ""
            }}
          </template>
          <template v-slot:cell(boatName)="data">
            <router-link :to="'/boat/' + data.item.boatId">{{
              data.item.boatName
            }}</router-link>
          </template>
        </b-table>
        <b-pagination
          id="pagination"
          v-model="currentPage"
          :total-rows="filteredAndSearchedBoats.length"
          :per-page="form.boatNumber"
          align="center"
        >
        </b-pagination>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { DateTime } from 'luxon';

import orderBy from 'lodash.orderby';

export default {
  name: 'Ranking',
  data() {
    const commonFields = [
      { key: 'rank', label: 'Classement' },
      { key: 'boatId', label: 'Code bateau' },
      { key: 'boatName', label: 'Nom' },
      { key: 'club', label: 'Club' },
      { key: 'boatType', label: 'Embarcation' },
      { key: 'category', label: 'Catégorie' },
      { key: 'boatYear', label: 'Année' },
      { key: 'level', label: 'Division' },
    ];
    return {
      showOverlay: false,
      boats: [],
      fieldsToutes: [
        ...commonFields,
        { key: 'raceCount', label: 'Nombre de courses' },
        { key: 'extras.nationalRacesCount', label: 'dont courses nationales' },
        { key: 'value', label: 'Moyenne' },
      ],
      filteredBoats: [],
      filteredAndSearchedBoats: [],
      boatTypes: ['Toutes'],
      categories: ['Toutes'],
      levels: ['Toutes'],
      form: {
        boatType: 'Toutes',
        boatNumber: 20,
        category: 'Toutes',
        level: 'Toutes',
        date: '2020-04-01',
        name: '',
      },
      currentPage: 1,
      updateDate: null,
    };
  },
  async created() {
    await this.getRanking();
  },
  methods: {
    async getRanking() {
      this.showOverlay = true;
      const response = await this.axios.get('/ffck/ranking/rankingCovid2021');
      if (response && response.data) {
        this.boats = response.data.rankedBoats;
        this.updateDate = response.data.updatedAt
          ? DateTime.fromISO(response.data.updatedAt).toFormat(
            'dd-MM-yyyy HH:mm',
          )
          : null;
        const categories = this.boats.map((e) => e.category);
        const uniqueCategories = [...new Set(categories)].sort();
        this.categories = ['Toutes'];
        if (uniqueCategories.includes('M') && uniqueCategories.includes('C')) {
          this.categories.push('M+C');
        }
        this.categories.push(...uniqueCategories);
        const boatTypes = this.boats.map((e) => e.boatType);
        const uniqueBoatTypes = [...new Set(boatTypes)].sort();
        this.boatTypes = ['Toutes', ...uniqueBoatTypes];
        const levels = this.boats.map((e) => e.level);
        const uniqueLevels = [...new Set(levels)].sort();
        this.levels = ['Toutes', ...uniqueLevels];
      }
      this.updateFilteredAndSearchedBoats();
      this.showOverlay = false;
    },
    updateFilteredBoats() {
      let filteredBoats = this.boats;
      if (this.form.boatType !== 'Toutes') {
        filteredBoats = filteredBoats.filter(
          (e) => e.boatType === this.form.boatType,
        );
      }
      if (this.form.category !== 'Toutes') {
        if (this.form.category === 'M+C') {
          filteredBoats = filteredBoats.filter((e) => ['M', 'C'].includes(e.category));
        } else {
          filteredBoats = filteredBoats.filter(
            (e) => e.category === this.form.category,
          );
        }
      }
      if (this.form.level !== 'Toutes') {
        filteredBoats = filteredBoats.filter(
          (e) => e.level === this.form.level,
        );
      }
      // this.boatsToutes = orderBy(
      //   boats, ['raceCount', 'value'], ['desc', 'asc']).slice(0, this.form.boatNumber
      // );
      filteredBoats = orderBy(
        filteredBoats,
        ['raceCount', 'extras.nationalRacesCount', 'value'],
        ['desc', 'desc', 'asc'],
      );
      let rank = 0;
      this.filteredBoats = filteredBoats.map((boat) => {
        rank += 1;
        return {
          ...boat,
          rank,
        };
      });
    },
    updateSearchedBoats() {
      if (!this.form.name || this.form.name.trim() === '') {
        this.filteredAndSearchedBoats = this.filteredBoats;
        return;
      }
      this.filteredAndSearchedBoats = this.filteredBoats.filter(
        (boat) => boat.boatName.toLowerCase().includes(this.form.name.toLowerCase()),
      );
    },
    updateFilteredAndSearchedBoats() {
      this.updateFilteredBoats();
      this.updateSearchedBoats();
    },
  },
};
</script>

<style scoped>
div {
  font-family: "calibriregular";
  font-size: 14px;
  color: #7b7777;
  font-weight: 400;
}
p {
  font-size: 16px;
}
table {
  font-size: 12px;
}
select {
  font-size: 14px;
}
.page-item.active .page-link {
  background-color: #549fd0;
  border-color: #549fd0;
}

.page-link {
  color: #549fd0;
}
</style>
